import { createApp, defineAsyncComponent } from "vue"

import.meta.glob("element-plus/dist/index.css", { eager: true }) // first import base element-plus css
import.meta.glob("../assets/element-overrides.scss", { eager: true }) // TODO: this is re-styling element-plus components, this should disappear in the future
import.meta.glob("../../assets/stylesheets/application.scss", { eager: true })

import "@/config/datadog"
import RichTextEditor from "@/config/richtext-editor"
import "@/config/vendor"
import router from "@/router"
import * as auth from "@/router/auth"
import i18n from "../i18n"
import { store } from "../store"

import { VueQueryPlugin } from "@tanstack/vue-query"
// import { createQueryClient } from "@/config/query-client"

// mixins
import withoutWatchers from "@/mixins/without-watchers"

// plugins
import DirectivesPlugin from "@/plugins/directives-plugin"
import FetchPlugin from "@/plugins/fetch-plugin"
import NotifyPlugin from "@/plugins/notify-plugin"
import RealtimePlugin from "@/plugins/realtime-plugin"
import CrmPlugin from "./crm-plugin"
import NiceUi from "@nice-ui"

// component libraries
import ElementPlus from "element-plus"
import elementPlusGermanLocale from "element-plus/es/locale/lang/de"
import CrmComponents from "./crm-components"

import "chartist/dist/chartist.css"
import Chartist from "vue-chartist"

import "vue-easy-lightbox/dist/external-css/vue-easy-lightbox.css"
import VueEasyLightbox from "vue-easy-lightbox/dist/external-css/vue-easy-lightbox.esm.min.js"

import { VueInstance } from "@vueuse/core"
import { LoDashStatic } from "lodash"

const VueApp = defineAsyncComponent(() => import("@/views/App.vue"))

const app = createApp(VueApp)

import { getGoogleAPI } from "@/core/3rdparty/GoogleAPI"
getGoogleAPI() // trigger initial load of google api's

app.use(store)
app.use(router)
app.use(i18n)
app.use(VueQueryPlugin, {
  enableDevtoolsV6Plugin: true,
  // queryClient: createQueryClient(),
})

app.mixin(withoutWatchers)

app.use(DirectivesPlugin, { auth })
app.use(FetchPlugin, { router })
app.use(RealtimePlugin)
app.use(CrmPlugin)

// components
app.use(ElementPlus, { i18n: (key, value) => i18n.global.t(key, value), locale: elementPlusGermanLocale })
app.use(NiceUi, { t: (key, value) => i18n.global.t(key, value), locale: i18n.global.locale })

app.use(CrmComponents)

app.use(Chartist)
app.use(VueEasyLightbox)
app.use(RichTextEditor)

app.use(NotifyPlugin) // depends on element-plus $message

app.config.performance = true // only works in development mode

async function bootstrapApp() {
  try {
    await router.isReady()
    window.vm = app.mount("#vue-app")
    // onSuccess
  } catch (err) {
    console.log("router error", err)
  }
}

bootstrapApp()

window.enableVueDevtools = () => {
  const devtools = (window as any).__VUE_DEVTOOLS_GLOBAL_HOOK__
  devtools.enabled = true
  const app = (Array.from(document.querySelectorAll("*")).find(e => (e as any).__vue_app__) as any).__vue_app__
  const version = app.version
  devtools.emit("app:init", app, version, {})
}

// TS shenanigans
// extend window object with global properties that are exposed here

declare global {
  interface Window {
    _: LoDashStatic
    Turbolinks: { visit: (to: string) => void }
    TodosApp: {
      fetchData?: () => void
    }
    zE: any
    TaskApp: {
      openTodoActivitesModal?: (todoId: number) => void
    }
    override_backdoor?: boolean
    vm: VueInstance
    enableVueDevtools: () => void
  }
}
